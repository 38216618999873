@import "../../assets/sass/variables.scss";

.post-page {
  .ant-layout {
    height: auto;
    margin-bottom: 0;

    .ant-layout-header {
      background: #fcfcfc;
      border-bottom: 1px solid #f2f2f7;

      @include mq() {
        padding: 0;
        background: #000000b3;
        height: 13%;
        width: 100%;
        line-height: normal;
      }

      .header-wrapper {
        max-width: 800px;
        margin: auto;
      }
    }

    .ant-layout-content {
      max-width: 1080px;
      margin: auto;
      background: #ffffff;
      padding: 52px 100px;
      min-height: initial;
      margin-bottom: 126px;

      @include mq() {
        width: auto;
        margin-bottom: 100px;
        padding: 0 0 13px 0;
        height: auto;
      }

      .ant-row {
        align-items: center;
      }
    }

    .bottom {
      height: 100vh;
    }

    .ant-layout-footer {
      height: 126px;
      width: 100vw;
      background: transparent
        linear-gradient(180deg, #ff8084e6 0%, #f9957e 100%) 0% 0% no-repeat
        padding-box;
      align-items: center;
      display: flex;
      position: fixed;
      bottom: 0;

      @include mq() {
        height: 6%;
        padding: 0;
      }
    }
  }

  .ant-divider {
    color: $greyColor;
  }

  .ant-divider-horizontal {
    margin: 24px 0 30px 0;
    @include mq() {
      margin: 15px 0;
    }
  }

  .header-app-download {
    background: #f2f2f2;
    height: 80px;
    padding: 10px;

    .ant-row {
      flex-wrap: nowrap;
      align-items: center;
    }

    .app-icon {
      width: 60px;
      height: 60px;
    }

    .info {
      line-height: 20px;
      margin-left: 20px;

      .title {
        font-size: 12px;
      }

      .publisher {
        color: #767575;
        font-size: 11px;
      }

      .sub {
        color: #767575;
        font-size: 12px;
      }
    }

    .show {
      color: #6b9afb;
    }
  }

  .name {
    font-size: 15px;
    margin: 0 auto;
    padding-top: 25px;
  }

  .post-row1 {
    @include mq() {
      display: flex;
      margin: 0;
      padding: 0 15px;
    }

    .post-top {
      display: flex;
    }

    .post-wrapper {
      padding: 10px 15px;
      display: flex;
    }

    .post-right-wrapper {
      margin-left: 15px;

      .text {
        margin-top: 10px;
      }
    }

    .post-singlepost {
      display: flex;
    }

    .post-left-wrapper {
      @include mq() {
        position: relative;
      }

      .tree {
        @include mq() {
          top: 50px;
          left: 23px;
          position: absolute;
          border-left: 4px solid #b2b2b2;
        }
      }
    }

    .post-name {
      font-size: 30px;

      @include mq() {
        margin-right: 10px;
        font-size: 15px;
      }
    }

    .post-time {
      font-size: 25px;
      color: $greyColor;
      line-height: 47px;

      @include mq() {
        font-size: 13px;
        line-height: 23px;
      }
    }

    .ant-col {
      .gutter-row {
        padding-left: 0 !important;
      }
    }
  }

  .post-row2 {
    font-size: 20px;

    @include mq() {
      font-size: 10px;
    }

    .ant-col {
      margin-right: 0 !important;
      padding-right: 0 !important;
    }

    .number {
      color: $primaryColor;
      display: inline-block;
    }
  }

  .post-row3 {
    font-size: 30px;
    margin: 30px 0;

    @include mq() {
      font-size: 13px;
      margin: 13px 0;
      padding: 0 15px;
    }

    .image-wrapper {
      text-align: center;
      position: relative;
    }

    .num-image {
      font-size: 25px;
      width: 55px;
      height: 55px;
      border-radius: 12px;
      background: #b2b2b2b3;
      color: #ffffff;
      position: absolute;
      top: 50px;
      left: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq() {
        font-size: 13px;
        width: 27px;
        height: 27px;
        border-radius: 6px;
        top: 25px;
        left: 15px;
      }
    }

    img {
      width: 90%;
      margin-top: 30px;
      border-radius: 22px;

      @include mq() {
        width: 100%;
        margin-top: 10px;
        border-radius: 11px;
      }
    }
  }

  .post-row4 {
    font-size: 20px;
    align-items: center;

    @include mq() {
      font-size: 10px;
    }

    .ant-col {
      margin-right: 0 !important;
      padding-right: 0 !important;
      color: #707070;
    }

    .heart {
      font-size: 39px;

      @include mq() {
        font-size: 20px;
      }
    }

    .lile-number {
      font-size: 25px;
      line-height: 50px;
      margin: 0 8px;

      @include mq() {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .reply {
    margin-bottom: 50px;

    @include mq() {
      margin-bottom: 25px;
    }

    .ant-col {
      margin-right: 20px;
    }

    .user-avator {
      position: relative;
    }

    .tree {
      height: 107px;
      border-left: 8px solid #b2b2b2;
      position: absolute;
      top: 100px;
      left: 46px;
    }

    .reply-row1 {
      .post-name {
        font-size: 30px;

        @include mq() {
          font-size: 15px;
        }
      }

      .ant-col {
        .gutter-row {
          padding-left: 0 !important;
        }
      }

      .post-time {
        font-size: 25px;
        color: $greyColor;
        line-height: 47px;

        @include mq() {
          font-size: 13px;
          margin: 0;
        }
      }

      @include mq() {
        margin: 0;
      }
    }

    .reply-row2 {
      font-size: 20px;

      .ant-col {
        margin-right: 0 !important;
        padding-right: 0 !important;
      }

      .number {
        color: $primaryColor;
        display: inline-block;
      }
    }

    .reply-row3 {
      font-size: 20px;
      align-items: center;

      @include mq() {
        font-size: 10px;
      }

      .ant-col {
        margin-right: 0 !important;
        padding-right: 0 !important;
        color: #707070;
      }

      .heart {
        font-size: 39px;

        @include mq() {
          font-size: 20px;
        }
      }

      .lile-number {
        font-size: 25px;
        line-height: 50px;
        margin: 0 8px;

        @include mq() {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }

  .reply:last-child {
    .tree {
      height: 0;
    }
  }

  .app-download {
    max-width: 880px;
    align-items: center;
    justify-content: center;
    margin: auto;

    .ant-row {
      justify-content: center;
    }

    .message {
      font-size: 25px;
      color: #ffffff;
      margin: 0 60px 0 20px;

      @include mq() {
        font-size: 10px;
        margin: 0;
        padding: 0 20px 0 10px;
      }
    }

    .app-store {
      margin-right: 8px;
      width: 140px;
      &:hover {
        cursor: pointer;
      }
    }

    .google-play {
      width: 176px;
      &:hover {
        cursor: pointer;
      }
    }

    .button {
      font-size: 10px;
      padding: 6px 12px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 50px;
    }
  }
}

@import "../../assets/sass/variables.scss";

body {
  background-color: #ffffff !important;
  height: 100vh;
}

.faq-main-container {
  overflow: hidden;

  .header-wrapper {
    text-align: center;

    img {
      margin: 40px 0 20px 0;
      width: 36%;

      @include mq() {
        width: 87%;
      }
    }
  }

  .ant-layout {
    width: 100%;
    margin: 0;
    position: relative;
    top: 20px;
    background: #ff7f9c;
    z-index: 1;
    padding: 0 0 100px 0;
  }

  .ant-layout::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ff7f9c;
    transform: skewY(-3deg);
    z-index: -1;

    @include mq() {
      transform: skewY(-5deg);
    }
  }

  .ant-layout::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ff7f9c;
    transform: skewY(3deg);
    z-index: -1;

    @include mq() {
      transform: skewY(5deg);
    }
  }

  .ant-layout-content {
    min-height: 80vh;
  }

  .faq-container {
    width: 52.1%;
    margin: 0 auto;

    @include mq() {
      width: 92%;
    }
    .ant-breadcrumb {
      margin: 30px 0 15px;
    }

    .ant-breadcrumb-separator {
      color: #fff;
    }

    .ant-breadcrumb-link {
      color: #fff;
    }

    .ant-breadcrumb-link > .anticon + span {
      text-decoration: underline;
    }

    .ant-breadcrumb > span:last-child {
      font-weight: 600;
    }

    .input-wrapper {
      margin-bottom: 39px;
    }

    .label {
      color: #ffffff;
      font-size: 2em;
      text-align: center;
      margin: 62px 0 39px 0;
      font-weight: 600;

      @include mq() {
        font-size: 1.2em;
        margin: 29px 0 15px 0;
      }
    }

    input {
      font-size: 2em;
      width: 100%;
      padding: 43px;
      height: 115px;
      border-radius: 100px;
      border: transparent;

      @include mq() {
        font-size: 1.2em;
        padding: 17px;
        height: 48px;
      }
    }

    ::placeholder {
      color: #b2b2b2;
    }

    .result {
      color: #ffffff;
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 30px;
      font-weight: 600;

      @include mq() {
        font-size: 2em;
        margin-bottom: 15px;
      }
    }

    .faq {
      cursor: pointer;
      color: #4b4b4b;
      font-size: 1.5em;
      background: #ffffff;
      padding: 50px 60px;
      margin-bottom: 25px;
      border-radius: 20px;
      font-weight: 600;

      @include mq() {
        font-size: 1.2em;
        padding: 25px 30px;
        margin-bottom: 12.5px;
      }
    }
  }

  .result-answer {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 30px;

    @include mq() {
      margin-bottom: 15px;
    }
  }

  .category-container {
    .title {
      color: #ffffff;
      font-size: 2.5em;
      text-align: center;
      margin: 60px 0 30px;
      font-weight: 600;

      @include mq() {
        font-size: 2em;
        margin-bottom: 15px;
      }
    }

    .category-items {
      width: 52.1%;
      margin: 0 auto;
      background-color: #00000014;
      border-radius: 12px;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @include mq() {
        width: 92%;
      }
    }

    .category {
      min-width: 90px;
      height: 27px;
      text-align: center;
      background-color: #fff;
      padding: 3px 10px;
      border-radius: 19px;
      margin: 3px 5px;
      color: #4b4b4b;
    }
  }
}

.detail-main-container {
  .detail-header-wrapper {
    text-align: center;
    border-bottom: 1px solid #ef869d;
    margin-bottom: 66px;

    @include mq() {
      margin-bottom: 15px;
    }

    img {
      width: 8.3%;
      cursor: pointer;

      @include mq() {
        width: 32.5%;
        margin: 12px;
      }
    }
  }

  .ant-layout {
    background: #ffffff;
  }

  .detail-container {
    width: 52.1%;
    margin: 0 auto;

    @include mq() {
      width: 92%;
    }

    .breadcrumb {
      font-size: 12px;
      margin-bottom: 15px;
      color: #4b4b4b;
    }

    .ant-breadcrumb-link {
      color: #4b4b4b;
    }

    .ant-breadcrumb-separator {
      color: #b2b2b2;
    }

    .ant-breadcrumb a {
      color: #1b94e0;
    }

    .ant-breadcrumb > span:last-child {
      font-weight: 600;
    }

    .faq-top {
      margin-bottom: 130px;

      @include mq() {
        margin-bottom: 65px;
      }

      .title {
        font-size: 2em;
        color: #4b4b4b;
        font-weight: bold;

        @include mq() {
          font-size: 1.5em;
          line-height: 1.5em;
          padding: 0;
        }
      }

      .line {
        margin-top: 20.5px;
        margin-bottom: 50px;
        border: 3px solid #ef869d;

        @include mq() {
          margin-top: 10px;
          margin-bottom: 15px;
        }
      }

      .answer {
        font-size: 1em;
        line-height: 2em;
        color: #4b4b4b;

        @include mq() {
          font-size: 1.2em;
        }
      }
    }

    .faq-image {
      width: 100%;
    }
  }

  .faq-bottom {
    background: #f5f5f5;
    height: 100%;
    min-height: 75vh;
    font-weight: 600;

    @include mq() {
      min-height: 60vh;
      padding: 0 0 60px 0;
    }

    .title {
      text-align: center;
      color: #4b4b4b;
      font-size: 2em;
      padding: 80px 0 30px 0;

      @include mq() {
        font-size: 1.2em;
        padding: 40px 0 15px 0;
      }
    }

    .faq {
      cursor: pointer;
      width: 52.1%;
      margin: 0 auto;
      color: #4b4b4b;
      font-size: 1.5em;
      background: #ffffff;
      padding: 50px 60px;
      margin-bottom: 25px;
      border-radius: 20px;

      @include mq() {
        width: 92%;
        font-size: 1.2em;
        padding: 25px 30px;
        margin-bottom: 12px;
      }
    }
  }
}

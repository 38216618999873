$primaryColor: #ff7f9c;
$secondaryColor: #e5e2cf;
$darkText: #312b2b;
$greyColor: #b2b2b2;
$bg: #f8f9fb;

$breakpoints: (
  "sp": "screen and (max-width: 414px)",
  "pc": "screen and (min-width: 415px)",
  // "lg": "screen and (min-width: 960px)",
  // "xl": "screen and (min-width: 1200px)",,,,,,,,,,,
) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin section {
  margin-bottom: 35px;

  @include mq() {
    margin: 15px;
  }
}

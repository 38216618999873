@import "../../assets/sass/variables.scss";

.user-page {
  .ant-layout {
    background: none;

    .ant-layout-header {
      background: white;
      border-bottom: 1px solid #707070;

      .header-wrapper {
        max-width: 800px;
        margin: auto;

        .header-app-download {
          margin: 0;
        }
      }
    }

    .ant-layout-content {
      max-width: 800px;
      margin: auto;
      background: none;
    }
  }
}

.basic-profile {
  @include mq(pc) {
    margin-bottom: 35px;
  }
  margin-top: 50px;

  @include mq() {
    margin-top: 0;
  }

  .sp-profile-name {
    font-size: 15px;
    padding: 14px 0;
    border-bottom: solid 1px $greyColor;
    text-align: center;
  }

  .basic-profile-content {
    @include mq() {
      margin: 15px;

      .wrapper-row {
        margin-bottom: 20px;
      }
    }
  }

  .ant-row {
    padding-bottom: 20px;

    @include mq() {
      padding-bottom: 0;
    }
  }

  .user-avatar {
    margin-right: 20px;
  }

  .user-row1 {
    margin-top: 20px;
    font-size: 30px;

    @include mq() {
      margin: 0;
    }
  }

  .follow-btn {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
  }

  .user-row2 {
    font-size: 20px;

    @include mq() {
      font-size: 15px;
      margin-top: 10px;
    }

    .ant-col {
      margin-right: 0 !important;
      padding-right: 0 !important;

      @include mq() {
        padding-right: 10px !important;
      }
    }

    .number {
      color: $primaryColor;
      display: inline-block;
    }
  }

  .introduction {
    font-size: 15px;
  }
}

.my-rooms {
  @include section;

  .title {
    color: $primaryColor;
  }

  .room-card {
    filter: drop-shadow(0 0 0.3rem #a9a9a9);

    .ant-card-body {
      padding: 10px;
    }

    .row-main {
      margin-bottom: 6px;

      .col2 {
        position: relative;
        width: 40px;
        margin: 0 10px;

        .room-icon-type {
          position: absolute;
          bottom: 0px;
        }
      }

      .col3 {
        position: relative;

        .room-name {
          font-size: 15px;
          height: 40px;
          position: absolute;
          bottom: 0px;
        }
      }
    }

    .caption {
      color: $greyColor;
      font-size: 11px;
    }

    .number {
      display: inline-block;
      margin-right: 35px;
    }
  }
}

.conversation-topics {
  @include section;

  .topic-card {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: white;
    background-color: #ff8084;
    border-radius: 6px;

    @include mq() {
      height: 25px;
      line-height: 25px;
      font-size: 11px;
    }

    .ant-card-body {
      padding: 0;
    }
  }
}

.detailed-profile {
  @include section;

  .profile-row {
    font-size: 20px;
    display: flex;
    padding: 4px 100px 4px 20px;
    margin-top: 22px;
    border-bottom: 1px solid $greyColor;

    @include mq() {
      font-size: 13px;
      padding: 4px 46px 4px 10px;
      margin-top: 10px;
    }

    .content {
      margin-left: auto;
    }
  }
}

.app-download {
  @include section;

  margin: auto;
  text-align: center;
  color: $primaryColor;
  font-size: 20px;

  .message {
    margin-bottom: 30px;
  }

  .app-store {
    margin-right: 30px;
    &:hover {
      cursor: pointer;
    }
  }

  .google-play {
    width: 174px;
    &:hover {
      cursor: pointer;
    }
  }
}

.header-app-download {
  background: #f2f2f2;
  height: 80px;
  padding: 10px;

  .ant-row {
    flex-wrap: nowrap;
    align-items: center;
  }

  .app-icon {
    width: 60px;
    height: 60px;
  }

  .info {
    line-height: 20px;
    margin-left: 20px;

    .title {
      font-size: 12px;
    }

    .publisher {
      color: #767575;
      font-size: 11px;
    }

    .sub {
      color: #767575;
      font-size: 12px;
    }
  }

  .show {
    color: #6b9afb;
  }
}
